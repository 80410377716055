import Vuelidate from 'vuelidate'
import { required,  email, minLength, sameAs} from 'vuelidate/lib/validators'
import axios from 'axios'
import VueAxios from 'vue-axios'
import TurbolinksAdapter from 'vue-turbolinks';      
  document.addEventListener('turbolinks:load', () => {

    if (document.getElementById("devise_new_password")) {
      Vue.use(Vuelidate);
      Vue.use(VueAxios, axios)
      Vue.use(TurbolinksAdapter)
      // Devise Password New view
    var v =  new Vue({
        el: '#devise_new_password',
        data: {
          emailValue: '',
          emailValid: false
        },

        
        validations: {
          emailValue: {
            email,
            required
          }
        },

        methods: {

          validateEmail(){
            if(!this.$v.emailValue.invalid) {
              axios.get( '/api/pfi/people/email_exist/' + btoa( this.emailValue )).then((response) => {
                this.emailValid = response.data
              }).catch(function(response) {console.log(response)})
            }
          }
        }
      });
    }

    if (document.getElementById("devise_edit_password")) {
    Vue.use(Vuelidate);
    var v2 = new Vue({
      el: "#devise_edit_password",
      data: {
        passwordValue: "",
        passwordConfirmation: ''
      },
      validations: {
        passwordValue: {
          required,
          minLength: minLength(8)
        },
        passwordConfirmation: {
          required,
          sameAsPassword: sameAs('passwordValue')
        }
      }

    })
    }


  });;
