document.addEventListener('turbolinks:load', function(){
  if (document.getElementById("lesson_attendances_form")) {

    new Vue({
      el: '#lesson_attendances_form',

      data: {
        errors_count: 0
      },

      methods: {
        toggleExcuseField: function(attendance_id){
          if ($('#attendances_' + attendance_id + '_has_excuse').is(":checked")) {
            $('#attendances_' + attendance_id + '_excuse').removeAttr('readonly');
            this.addExcuseError(attendance_id);
          } else {
            $('#attendances_' + attendance_id + '_excuse').val('');
            $('#attendances_' + attendance_id + '_excuse').attr('readonly', true);
            this.removeExcuseError(attendance_id);
          }
        },

        validateExcuse: function(attendance_id){
          var excuse = $('#attendances_' + attendance_id + '_excuse').val();
          if (excuse == "") {
            this.addExcuseError(attendance_id);
          } else {
            this.removeExcuseError(attendance_id);
          }
        },

        addExcuseError: function(attendance_id) {
          $('#form_group_' + attendance_id).addClass('has-error');
          this.errors_count += 1;
        },

        removeExcuseError: function(attendance_id) {
          $('#form_group_' + attendance_id).removeClass('has-error');
          if (this.errors_count > 0) { this.errors_count -= 1; }
        }
      },
    });
  }
});
