import consumer from './consumer'
import CableReady from 'cable_ready'

let subscription;

document.addEventListener("turbolinks:load", () => {
  function addTabIdentifierElement(reflex = false){
    if(subscription && !reflex) consumer.disconnect()

    let forms = document.querySelectorAll("form")

    forms.forEach((form) => {
      form.insertAdjacentHTML("afterbegin", `<input type="hidden" id="tab" name="tab" value="${tabIdentifier}" data-reflex-permanent>`)
    })
  }

  addTabIdentifierElement()

  subscription = consumer.subscriptions.create('OptimismChannel', {
    connected(){
      document.addEventListener('stimulus-reflex:after', event => {
        addTabIdentifierElement(true)
      })
    },
    received (data) {
      if (data.cableReady)
        CableReady.perform(data.operations, {
          emitMissingElementWarnings: false
        })
    }
  })
})
