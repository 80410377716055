
import Vue from 'vue/dist/vue.min.js'
window.Vue = Vue;

import 'channels/optimism_channel.js'

import './zones/churches/d2/courses/attendances.js'
import './zones/churches/d2/courses/rates.js'
import './zones/churches/pfi/people.js'
import './passwords.js'
import './donations.js'
import './zones/churches/pfi/fama.js'



import "controllers"
