/*jshint esversion: 6 */
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { required, email } from 'vuelidate/lib/validators';




document.addEventListener('turbolinks:load', function(){
  if (document.getElementById('pfi_person_form')) {
    Vue.use(Vuelidate);
    Vue.use(VueAxios, axios)
    
    var currentAction = document.getElementById('pfi_person_form').dataset.action;
    var currentId = document.getElementById('pfi_person_form').dataset.id;
    var zoneId = document.getElementById('pfi_person_form').dataset.zoneid;
    var churchId = document.getElementById('pfi_person_form').dataset.churchid;

    axios.defaults.baseURL = '/a/zones/' + zoneId + '/churches/' + churchId + '/pfi/people';
    axios.defaults.headers.common['X-CSRF-TOKEN'] = document.getElementsByName('csrf-token')[0].getAttribute('content');

    new Vue({
      el: '#pfi_person_form',

      data: {
        identification: document.getElementById("person_form").getAttribute('data-person_id'),
        identification_type: document.getElementById("person_form").getAttribute('data-person_id_type'),
        graded: document.getElementById("person_form").getAttribute('data-graded') === "true" ? true : false,
        person: {
          id: '',
          date_of_birth: '',
          place_of_birth: '',
          identification_type: '',
          identification: '',
          first_name: '',
          last_name: '',
          email: '',
          gender: '',
          marital_status: '',
          spouse_name: '',
          spouse_identification: '',
          spouse_is_christian: '',
          role_zone_id: '',
          role_church_id: '',
          role_area_id: '',
          role_network_id: '',
          invitation_sent: null,
          invitation_sent_at: '',
        },
        identification_exist: false,
        identification_belongs_to: '',
        email_exist: false,
        currentTab: 'general',
        currentRole: 'none',
        isMarried: false,
        pfiRolesOptions: [
          { text: 'Ninguno', value: 'none' },
          { text: 'Administrador', value: 'pfi_administrator' },
          { text: 'Supervisor de Zona', value: 'pfi_zone_supervisor' },
          { text: 'Supervisor de Iglesia', value: 'pfi_church_supervisor' },
          { text: 'Supervisor de Área', value: 'pfi_area_supervisor' },
          { text: 'Supervisor de Red', value: 'pfi_network_supervisor' },
          { text: 'Ayudante de Iglesia', value: 'pfi_church_assistant' }
        ],
        supervisable_type: '',
        supervisable_id: '',
        showNetworksField: true
      },

      mounted() {
        if (currentAction === 'edit') {
          var self = this;
          this.fetchPerson();

          setTimeout(function(){
            self.toggleMaritalStatus();
            self.new_chruch_id = churchId;
            self.fetchRole();
          }, 500);
        }
      },

      methods: {
        fetchPerson() {
          var self = this;

          axios.get('/person', {
              params: {
                id: currentId
              }
            })
            .then(function (response) {
              self.person.id = response.data.id;
              self.person.date_of_birth = response.data.date_of_birth;
              self.person.place_of_birth = response.data.place_of_birth;
              self.person.identification_type = response.data.identification_type;
              self.person.identification = response.data.identification;
              self.person.first_name = response.data.first_name;
              self.person.last_name = response.data.last_name;
              self.person.email = response.data.email;
              self.person.gender = response.data.gender;
              self.person.marital_status = response.data.marital_status;
              self.person.spouse_name = response.data.spouse_name;
              self.person.spouse_identification = response.data.spouse_identification;
              self.person.spouse_is_christian = response.data.spouse_is_christian;
              self.person.role_zone_id = response.data.role_zone_id;
              self.person.role_church_id = response.data.role_church_id;
              self.person.role_area_id = response.data.role_area_id;
              self.person.role_network_id = response.data.role_network_id;
              self.person.invitation_sent = response.data.invitation_sent;
              self.person.invitation_sent_at = response.data.invitation_sent_at;
            })
            .catch(function (error) { return false; });
        },

        hideNetworksField() {
          this.showNetworksField = false;
        },

        isValidIdentification(){
          var self = this
           axios.get('/identification_unique', {
            params: {
              id: self.person.id,
              identification: self.identification,
              identification_type: self.identification_type
            }
           }).then(response => (self.identification_exist = response.data.exist));
        },

        toggleMaritalStatus() {
          if (this.person.marital_status === 'married') {
            this.isMarried = true;
          } else {
            this.person.spouse_name = '';
            this.person.spouse_identification = '';
            this.person.spouse_is_christian = '';
            this.isMarried = false;
          }
        },

        setCurrentTab: function(tab) { this.currentTab = tab; },

        fetchRole: function() {
          var self = this;

          axios.get('/fetch_role', {
              params: {
                id: currentId
              }
            })
            .then(function (response) {
              self.currentRole = response.data.role;
            })
            .catch(function (error) {});
        },

        assignRole: function(role) {
          this.currentRole = role;

          if (role === 'none' || role === 'pfi_administrator') {
            this.person.role_zone_id    = '';
            this.person.role_church_id  = '';
            this.person.role_area_id    = '';
            this.person.role_network_id = '';
          }

          if (role === 'pfi_zone_supervisor') {
            this.supervisable_type      = 'Zone';
            this.supervisable_id        = this.person.role_zone_id;
            this.person.role_church_id  = '';
            this.person.role_area_id    = '';
            this.person.role_network_id = '';
          }

          if (role === 'pfi_church_supervisor' || role === 'pfi_church_assistant') {
            this.supervisable_type      = 'Church';
            this.supervisable_id        = churchId;
            this.person.role_church_id  = churchId;
            this.person.role_zone_id    = '';
            this.person.role_area_id    = '';
            this.person.role_network_id = '';
          }

          if (role === 'pfi_area_supervisor') {
            this.supervisable_type      = 'Area';
            this.supervisable_id        = this.person.role_area_id;
            this.person.role_church_id  = churchId;
            this.person.role_zone_id    = '';
            this.person.role_network_id = '';
          }

          if (role === 'pfi_network_supervisor') {
            this.supervisable_type      = 'Network';
            this.supervisable_id        = this.person.role_network_id;
            this.person.role_church_id  = churchId;
            this.person.role_zone_id    = '';
            this.person.role_area_id    = '';
          }

          axios.post('/assign_role', {
              id: currentId,
              role: role,
              role_zone_id: this.person.role_zone_id,
              role_church_id: this.person.role_church_id,
              role_area_id: this.person.role_area_id,
              role_network_id: this.person.role_network_id,
              supervisable_type: this.supervisable_type,
              supervisable_id: this.supervisable_id,
            })
            .then(function (response) {})
            .catch(function (error) {});
        },

        send_invitation: function() {
          var self = this;
          $('#send_invitation_modal').modal('toggle');

          axios.post('/send_invitation', {
              id: currentId
            })
            .then(function (response) {
              self.person.invitation_sent = response.data.invitation_sent;
              self.person.invitation_sent_at = response.data.invitation_sent_at;
            })
            .catch(function (error) {});
        }
      },

      validations: {
        person: {
        	date_of_birth: { required },
          place_of_birth: { required },
          first_name: { required },
          last_name: { required },
          gender: { required },
          email: {
            email,
            isEmailUnique (value) {
              var self = this;

              if (value === '') return true;

              axios.get('/email_unique', {
                  params: {
                    id: self.person.id,
                    email: btoa( this.person.email )
                  }
                })
                .then(function (response) {
                  self.email_exist = response.data.exist;
                })
                .catch(function (error) {});
              return !self.email_exist;
            }
          },
          identification_type: { required },
          identification: {
            required,
            isIdentificationUnique (value) {
              var self = this;

              if (value === '') return true;

              axios.get('/identification_unique', {
                  params: {
                    id: self.person.id,
                    identification: self.person.identification,
                    identification_type: self.person.identification_type
                  }
                })
                .then(function (response) {
                  if (response.data.exist === true) {
                    self.identification_exist = response.data.exist;
                    self.identification_belongs_to = response.data.belongs_to;
                  } else {
                    self.identification_exist = response.data.exist;
                    self.identification_belongs_to = '';
                  }
                })
                .catch(function (error) { return false; });  
              return !self.identification_exist;
            }
          }
        }
      }
    });
}})
