document.addEventListener('turbolinks:load', function(){
  if (document.getElementById("rates")) {
    new Vue({
      el: '#rates',

      data: {
        errors_count: 0
      },

      methods: {
        validateScore: function(activity_id){
          var score = $('#rates_' + activity_id + '_score').val();
          if (score >= 1 && score <= 5) {
            $('#form_group_' + activity_id).removeClass('has-error');
            if (this.errors_count > 0) { this.errors_count -= 1; }
          } else {
            $('#form_group_' + activity_id).addClass('has-error');
            this.errors_count += 1;
          }
        }
      }
    });
  }
});
