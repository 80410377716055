import axios from 'axios'
import VueAxios from 'vue-axios'
import TurbolinksAdapter from 'vue-turbolinks';

document.addEventListener('turbolinks:load', function(){
  if (document.getElementById("fama")) {
    Vue.use(TurbolinksAdapter)
    var f = document.getElementById("fama").getAttribute('leader')
    var as = document.getElementById("fama").getAttribute('helper')
    var m = document.getElementById("fama").getAttribute('teacher')
    var an = document.getElementById("fama").getAttribute('host')
    new Vue({
      el: '#fama',

      data: {
        people: {},
        leader: (f !== '') ? JSON.parse(f): "",
        helper: (as !== '') ? JSON.parse(as): "",
        teacher: (m !== '') ? JSON.parse(m): "",
        host: (an !== '') ? JSON.parse(an): "",
        criteria: ''
      },
      methods: {
        titleize(sentence) {
            if(!sentence.split) return sentence;
            var _titleizeWord = function(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
                },
                result = [];
            sentence.split(" ").forEach(function(w) {
                result.push(_titleizeWord(w));
            });
            return result.join(" ");
        },
        selected(person){
          return (person === this.leader || person === this.helper || person === this.teacher || person === this.host)          
        },
        fetch_person(){
          axios.get( '/api/pfi/people/find_person/' + this.criteria ).then((response) => {
          this.people = response.data
          }).catch(function(response) {console.log(response)})
        },
        full_name(person){
          if(person !== ''){
            return `${this.titleize(person.first_name.toLowerCase())} ${this.titleize(person.last_name.toLowerCase())}`
          }
          else {
            return ''
          }
        }
      }
    });
  }
});
