import axios from 'axios/dist/axios.min.js'

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.getElementsByName('csrf-token')[0].getAttribute('content');

document.addEventListener('turbolinks:load', function(){

  if (document.getElementById("new_donation_form")) {
    var baseApiURL = '/p/donations';

    var app = new Vue({
      el: '#new_donation_form',

      data: {
        numeralFormat: '0,0',
        minimumAmount: 20000,
        ncf: {},
        donation: {
          id: '',
          first_name: '',
          last_name: '',
          amount: '50000',
          identification_type: 'cc',
          identification: '',
          company_name: '',
          country: 'CO',
          currency: 'COP',
          donation_type: 'spike',
          email: '',
          lng: 'es',
          signature: ''
        },
        translation: {
          amount: '',
          identification_type: '',
          identification_type_options: {
            cc:  '',
            ce:  '',
            ic:  '',
            pst: '',
            ci:  ''
          },
          identification: '',
          first_name: '',
          last_name: '',
          company_name: '',
          country: '',
          donation_type: '',
          donation_type_options: {
            spike: '',
            offerings: '',
            tithes: '',
            missions: '',
            congress: '',
            sb: '',
            pm: '',
            pfi: ''
          },
          email: '',
          givebutton: '',
          important_information: '',
          language_title: '',
          language_currency: '',
          payment_methods: '',
          payment_methods_credit_card: '',
          payment_methods_cash: '',
          payment_methods_bank: ''
        }
      },

      mounted: function() {
        this.translateToSpanish();
      },

      computed: {
        fullName: function() {
          return this.donation.first_name + ' ' + this.donation.last_name;
        },

        amount: function() {
          return this.convertToAmountNumnber();
        },

        referenceCode: function() {
          // Get donation DB id
          return this.donation.id;
        },

        description: function() {
          var description = '';
          switch (this.donation.donation_type) {
            case "spike":
              description = "Siembra (Espiga)";
              break;
            case "missions":
              description = "Donación - Misiones";
              break;
            case "tithes":
              description = "Donación - Diezmos";
              break;
            case "congress":
              description = "Congresos";
              break;
            case "sb":
              description = "Seminario Bíblico";
              break;
            case "pm":
              description = "Primicias";
              break;
            case "pfi":
              description = "PFI";
              break;
            default:
              description = "Donación - Ofrendas";
          }
          return description;
        }
      },

      methods: {
        createOrUpdateDonation: function() {
          var self = this;

          this.donation.amount = this.convertToAmountNumnber();
          if (this.donation.id === '') {
            axios.post(baseApiURL, this.donation)
            .then(function (response) {
              self.donation.id = response.data.id;
            })
            .catch(function (error) {
            });

            this.$nextTick(function(){
              setTimeout(function(){ self.fetchSignature(); }, 1000);
            });
          }else {
            axios.put(baseApiURL + '/' + this.donation.id, this.donation)
            .then(function (response) {
            })
            .catch(function (error) {
            });

            this.$nextTick(function(){
              setTimeout(function(){ self.fetchSignature(); }, 1000);
            });
          }
          this.donation.amount =  numeral(this.donation.amount).format(this.numeralFormat);
        },

        calculateAmountAndSign: function() {
          this.createOrUpdateDonation()

          if (this.donation.amount.replace(/[\D]/g, "") < this.minimumAmount) {
            this.donation.amount = this.minimumAmount;
          }

          this.fetchSignature();
        },

        fetchSignature: function() {
          var self = this;
          axios.get(baseApiURL + '/' + this.donation.id + '/signature')
            .then(function (response) {
              self.donation.signature = response.data
            })
            .catch(function (error) {
            });
        },

        applyMoneyFormat: function() {
          this.donation.amount =  numeral(this.convertToAmountNumnber()).format(this.numeralFormat);
          this.createOrUpdateDonation();
        },

        convertToAmountNumnber: function() {
          if (this.donation.currency === 'COP') {
            return this.donation.amount.replace(/[,.]/g, "");
          }else{
            return this.donation.amount.replace(/[,]/g, ".");
          }
        },

        minAmount: function() {
          return this.convertToAmountNumnber() >= this.minimumAmount;
        },

        validateCompany: function( value ) {
          return value !== '';
        },

        setCurrency: function(currency) {
          this.donation.currency = currency;

          switch(currency) {
            case 'COP':
              this.numeralFormat = '0,0';
              this.donation.amount = '50,000';
              this.minimumAmount = 20000;
              break;
            case 'USD':
              this.numeralFormat = '0';
              this.donation.amount = '15';
              this.minimumAmount = 5;
              break;
            case 'BRL':
              this.numeralFormat = '0';
              this.donation.amount = '65';
              this.minimumAmount = 45;
              break;
          }
          this.createOrUpdateDonation();
        },

        // Translation functions
        translateToSpanish: function() {
          this.donation.lng = 'es';
          this.createOrUpdateDonation();

          this.translation.language_title = 'Idioma';
          this.translation.language_currency = 'Moneda';
          this.translation.amount = 'Cantidad';
          this.translation.min_amount = 'Monto mínimo';
          this.translation.identification_type = 'Tipo de identificación';
          this.translation.identification_type_options.cc  = 'Cedula de ciudadania';
          this.translation.identification_type_options.ce  = 'Cedula de extranjeria';
          this.translation.identification_type_options.ic  = 'Tarjeta de identidad';
          this.translation.identification_type_options.pst = 'Pasaporte';
          this.translation.identification_type_options.ci  = 'NIT';
          this.translation.identification = 'Identificación';
          this.translation.first_name = 'Nombres';
          this.translation.last_name = 'Apellidos';
          this.translation.company_name = 'Iglesia o Red a la que pertenece';
          this.translation.country = 'País';
          this.translation.donation_type = 'Tipo de donación';
          this.translation.donation_type_options.spike = 'Espiga';
          this.translation.donation_type_options.offerings = 'Ofrendas';
          this.translation.donation_type_options.tithes = 'Diezmos';
          this.translation.donation_type_options.missions = 'Misiones';
          this.translation.donation_type_options.sb = 'Seminario Bíblico';
          this.translation.donation_type_options.pm = 'Primicias';
          this.translation.donation_type_options.pfi = 'PFI';
          this.translation.donation_type_options.congress = 'Congresos';
          this.translation.email = 'Correo electrónico';
          this.translation.givebutton = 'Donar';

          this.translation.important = 'Importante';
          this.translation.important_information = 'Algunos de los campos son requeridos para el certificado de donación emitido por la Iglesia.';
          this.translation.payment_methods = 'Medios de pago para ';
          this.translation.payment_methods_int = 'INTERNACIONALES';
          this.translation.payment_methods_credit_card = 'Tarjeta de crédito';
          this.translation.payment_methods_cash = 'Pago en efectivo';
          this.translation.payment_methods_bank = 'Pago en bancos';
        },

        translateToEnglish: function() {
          this.donation.lng = 'en';
          this.createOrUpdateDonation();

          this.translation.language_title = 'Language';
          this.translation.language_currency = 'Currency';
          this.translation.amount = 'Amount';
          this.translation.min_amount = 'Minimum amount';
          this.translation.identification_type = 'Identification type';
          this.translation.identification_type_options.cc  = 'Citizenship card';
          this.translation.identification_type_options.ce  = 'Foreigner ID';
          this.translation.identification_type_options.ic  = 'Identity card';
          this.translation.identification_type_options.pst = 'Passport';
          this.translation.identification_type_options.ci  = 'TIN';
          this.translation.identification = 'Identification number';
          this.translation.first_name = 'First name';
          this.translation.last_name = 'Last name';
          this.translation.company_name = 'Church or Network you belongs to';
          this.translation.country = 'Country';
          this.translation.donation_type = 'Type of donation';
          this.translation.donation_type_options.spike = 'Spike';
          this.translation.donation_type_options.offerings = 'Offerings';
          this.translation.donation_type_options.tithes = 'Tithes';
          this.translation.donation_type_options.missions = 'Missions';
          this.translation.donation_type_options.sb = 'Seminario Bíblico';
          this.translation.donation_type_options.pm = 'Primicias';
          this.translation.donation_type_options.pfi = 'PFI';
          this.translation.email = 'Email';
          this.translation.givebutton = 'Give';

          this.translation.important = 'Important';
          this.translation.important_information = 'Some fields are required for donation certificate issued by the Church.';
          this.translation.payment_methods = 'Payment methods for ';
          this.translation.payment_methods_int = 'INTERNATIONAL';
          this.translation.payment_methods_credit_card = 'Credit Cards';
          this.translation.payment_methods_cash = 'Cash payment';
          this.translation.payment_methods_bank = 'Payment in banks';
        },

        translateToPortuguese: function() {
          this.donation.lng = 'pt';
          this.createOrUpdateDonation();

          this.translation.language_title = 'Idioma';
          this.translation.language_currency = 'Moeda';
          this.translation.amount = 'Quantidade';
          this.translation.min_amount = 'Quantidade mínima';
          this.translation.identification_type = 'Tipo de identificação';
          this.translation.identification_type_options.cc  = 'Cédula de cidadania';
          this.translation.identification_type_options.ce  = 'Cédula de extrangeiro';
          this.translation.identification_type_options.ic  = 'Cédula de identidade';
          this.translation.identification_type_options.pst = 'Passaporte';
          this.translation.identification_type_options.ci  = 'CPF';
          this.translation.identification = 'Identificação';
          this.translation.first_name = 'Nomes';
          this.translation.last_name = 'Sobrenomes';
          this.translation.company_name = 'Igreja ou rede à qual você pertence';
          this.translation.country = 'País';
          this.translation.donation_type = 'Tipo de doação';
          this.translation.donation_type_options.spike = 'Espinho';
          this.translation.donation_type_options.offerings = 'Ofertas';
          this.translation.donation_type_options.tithes = 'Dízimos';
          this.translation.donation_type_options.missions = 'Missões';
          this.translation.donation_type_options.sb = 'Seminario Bíblico';
          this.translation.donation_type_options.pm = 'Primicias';
          this.translation.donation_type_options.pfi = 'PFI';
          this.translation.email = 'Correio eletrônico';
          this.translation.givebutton = 'Doar';

          this.translation.important = 'Importante';
          this.translation.important_information = 'Alguns campos são requeridos para o certificado de doação emitido pela igreja';
          this.translation.payment_methods = 'Meios de pagamento para ';
          this.translation.payment_methods_int = 'INTERNATIONAL';
          this.translation.payment_methods_credit_card = 'Cartões de Crédito';
          this.translation.payment_methods_cash = 'Pagamento em Dinheiro';
          this.translation.payment_methods_bank = 'Pagamento em Bancos';
        }
      }
    });
  }
});
